import constants from '~/config/constants'

export const useSetCookies =
    () =>
    (expires = 0, token?: string) => {
        const tokenCookie = useCookie(constants.cookies.access_token_key, {
            path: '/',
            expires: new Date(expires),
            sameSite: 'strict',
        })

        const tokenExpirationCookie = useCookie(constants.cookies.token_expires_at_key, {
            path: '/',
            expires: new Date(expires),
            sameSite: 'strict',
        })

        if (!!expires) {
            tokenCookie.value = token

            tokenExpirationCookie.value = expires.toString()
        } else {
            tokenCookie.value = null

            tokenExpirationCookie.value = null
        }
    }
